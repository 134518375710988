import axios from "axios"

class BackendModel {
    getBaseUrl() {
        console.log(location.hostname)
        if (location.hostname === 'localhost') {
            return 'http://127.0.0.1:3000'
        }
        return ''
    }

    getBackofficeSessionId() {
        let name = 'backoffice_session_id'
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        let backoffice_session_id = parts.pop().split(';').shift()
        return backoffice_session_id
    }

    async backendRequest(url, params) {
        try {
            params.backoffice_session_id = this.getBackofficeSessionId()
            if (typeof params.append === 'function') {
                params.append('backoffice_session_id', this.getBackofficeSessionId())
            }
            let res = await axios.post(this.getBaseUrl() + url, params)
            return res
        } catch (e) {
            console.log(e)
        }
    }
}


export default BackendModel